// @ts-ignore
import { Checkbox as CheckboxAntd } from "antd";

export default function Checkbox({ ...props }) {
  const {
    children,
    disabled = false,
    type = "primary",
    className = "",
    ...remainingProps
  } = props;

  return (
    <CheckboxAntd
      className={`checkbox ${type} ${className}`}
      disabled={disabled}
      {...remainingProps}
    >
      {children}
    </CheckboxAntd>
  );
}
