import Button from './Button'

type PropType = {
    okText: string
    cancelText: string
    loading?: boolean
    handleCancel: () => void
    handleOK: () => void
}

export default function ButtonsModalConfirm({
    okText,
    cancelText,
    loading,
    handleCancel,
    handleOK,
}: PropType) {
    return (
        <div className='buttons-modal-confirm'>
            <Button
                onClick={handleOK}
                loading={loading}
                size='small'
                type='primary'>
                {okText}
            </Button>
            <Button onClick={handleCancel} type='ghost' size='small'>
                {cancelText}
            </Button>
        </div>
    )
}
