// @ts-ignore
import { Switch as SwitchAntd } from 'antd'

export default function Switch({ ...props }) {
    const {
        children,
        disabled = false,
        className = '',
        size = 'small',
        defaultChecked = true,
        loading = false,

        ...remainingProps
    } = props

    return (
        <SwitchAntd
            loading={loading}
            defaultChecked={defaultChecked}
            size={size}
            className={`switch ${className}`}
            disabled={disabled}
            {...remainingProps}>
            {children}
        </SwitchAntd>
    )
}
