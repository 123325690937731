import BarLoader from 'react-bar-loader'
const SpinnerBar = ({ children, loading }) => {
    return (
        <div style={{ position: 'relative', zIndex: 99 }}>
            {loading && (
                <BarLoader
                    color='#3B02BF'
                    height='2'
                    style={{ position: 'absolute', top: '-1px', width: '100%' }}
                />
            )}
            {children}
        </div>
    )
}
export default SpinnerBar
