const ColorList = (color: string) => {
    const colorListPalette = [
        '#5B8FF9',
        '#CDDDFD',
        '#61DDAA',
        '#CDF3E4',
        '#65789B',
        '#CED4DE',
        '#F6BD16',
        '#FCEBB9',
        '#7262FD',
        '#78D3F8',
        '#D3EEF9',
        '#9661BC',
        '#DECFEA',
        '#F6903D',
        '#FFE0C7',
        '#008685',
        '#BBDEDE',
        '#F08BB4',
        '#FFE0ED',
    ]
    return colorListPalette
}

export default ColorList
