// @ts-ignore
import { Button as ButtonAntd } from "antd";

export default function Button({ ...props }) {
  const {
    children,
    disabled = false,
    loading = false,
    type = "default",
    htmlType = "",
    ghost = false,
    className = "",
    ...remainingProps
  } = props;

  const loadStatus = loading ? "loading" : "";

  return (
    <ButtonAntd
      className={`buttons ${type} ${ghost && "ghost"} ${loadStatus}${
        disabled && "disabled"
      } ${className}`}
      disabled={disabled}
      loading={loading}
      htmlType={htmlType}
      type={type}
      {...remainingProps}
    >
      {children}
    </ButtonAntd>
  );
}
