import {
  RocketOutlined,
  SoundOutlined,
  BookOutlined,
  FileDoneOutlined,
  PictureOutlined,
  ToolOutlined,
  EyeOutlined,
  FileTextOutlined,
  TeamOutlined,
  BuildOutlined,
  PushpinOutlined,
  ScheduleOutlined,
  LineChartOutlined,
  AppstoreOutlined,
  SmileOutlined,
  StarOutlined,
  HeartOutlined,
  CloudOutlined,
  MailOutlined,
  ThunderboltOutlined,
  PhoneOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

export default function IconsList({ ...props }) {
  const list = [
    { name: "RocketOutlined", icon: <RocketOutlined /> },
    { name: "SoundOutlined", icon: <SoundOutlined /> },
    { name: "BookOutlined", icon: <BookOutlined /> },
    { name: "FileDoneOutlined", icon: <FileDoneOutlined /> },
    { name: "PictureOutlined", icon: <PictureOutlined /> },
    { name: "ToolOutlined", icon: <ToolOutlined /> },
    { name: "EyeOutlined", icon: <EyeOutlined /> },
    { name: "FileTextOutlined", icon: <FileTextOutlined /> },
    { name: "TeamOutlined", icon: <TeamOutlined /> },
    { name: "BuildOutlined", icon: <BuildOutlined /> },
    { name: "PushpinOutlined", icon: <PushpinOutlined /> },
    { name: "ScheduleOutlined", icon: <ScheduleOutlined /> },
    { name: "LineChartOutlined", icon: <LineChartOutlined /> },
    { name: "AppstoreOutlined", icon: <AppstoreOutlined /> },
    { name: "MailOutlined", icon: <MailOutlined /> },
    {
      name: "SmileOutlined",
      icon: <SmileOutlined />,
    },
    { name: "StarOutlined", icon: <StarOutlined /> },
    { name: "HeartOutlined", icon: <HeartOutlined /> },
    { name: "CloudOutlined", icon: <CloudOutlined /> },
    { name: "ThunderboltOutlined", icon: <ThunderboltOutlined /> },
    { name: "PhoneOutlined", icon: <PhoneOutlined /> },
    { name: "LinkedinOutlined", icon: <LinkedinOutlined /> },
  ];
  let result = list.find((item: any) => item.name === props.name);

  return <>{result?.icon}</>;
}
