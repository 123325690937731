// @ts-ignore
import { Spin } from 'antd'
import AntIconLoad from './AntIconLoad'

const Loading = () => {
    return (
        <div className='spi-loading'>
            <Spin size='large' indicator={AntIconLoad} />
        </div>
    )
}

export default Loading
