// @ts-ignore
import { Radio as RadioAntd } from 'antd'

export default function Radio({ ...props }) {
    const {
        children,
        disabled = false,
        type = 'primary',
        className = '',
        ...remainingProps
    } = props

    return (
        <RadioAntd
            className={`radio ${type} ${disabled && 'disabled'} ${
                className && className
            }`}
            disabled={disabled}
            type={type}
            {...remainingProps}>
            {children}
        </RadioAntd>
    )
}
